
/**
 * This class is used for keeping track of a set of transaction rows and their current value.
 */
class TransactionRowManager {

  /**
   *  @typedef {object} TransactionRowRecord - represents a transaction row being managed
   *  @property {HTMLDivElement=} row - the transction row in the DOM
   *  @property {HTMLSelectElement=} categorySelect - the category select element inside `row`
   *  @property {string} selectedCategory - the current category selected in `categorySelect`
   */


  /**
   * all of the transction rows being managed by this object
   *  @type Array<TransactionRowRecord>
   */
  _transactionRows = []

  /**
   * 
   * @param {() => JQuery<HTMLDivElement>} findRowsFn a function to get a JQuery object with a list of transction rows
   * to manage
   */
  constructor(findRowsFn) {
    this._findRowsFn = findRowsFn
  }

  /**
   * clears the list of transaction rows. Should only be used internally
   */
  _resetTransactionRows = () => {
    this._transactionRows.splice(0, this._transactionRows.length);
  }


  /**
   * Find a transaction row record for a given Category Select element
   * @param {HTMLSelectElement} element the row to sea
   * @returns {TransactionRowRecord} if a corresponding row was found
   * @returns {undefined} otherwise
   */
  getByCategorySelect = (element) => {
    return this._transactionRows.find((i) => i.categorySelectElement === element)
  }

  /**
   * clears `_transactionRows` and loads (or reloads) the transaction rows from the DOM using the `findRowsFn` passed into the constructor.
   * 
   * Make sure to run this any time rows are added or removed from the DOM OR the selected category on one of the row has changed
   * 
   * @returns {void}
   */
  loadTransactionRows = () => {
    this._resetTransactionRows()
    this._findRowsFn().each((_index, row) => {
      const categorySelectElement = $(row).find('select')[0];
      this._transactionRows.push({ row, categorySelectElement, selectedCategory: categorySelectElement.options[categorySelectElement.selectedIndex].text })
    });
  }
}

module.exports = TransactionRowManager