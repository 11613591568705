const refreshUsers = users => {
  $('.admin-dashboard-users').replaceWith(users)
}

const refreshExpenseCategories = expenseCategories => {
  $('.admin-dashboard-expense-categories').replaceWith(expenseCategories)
}

const clearUserForm = () => {
  const form = $('#new-user-form')[0]
  form.reset()
}

const clearExpenseCategoryForm = () => {
  const form = $('#new-expense-category-form-wrapper form')[0]
  form.reset()
}

const handleUserResponse = response => {
  const data = response.detail[0]
  if (data.users) {
    refreshUsers(data.users)
    clearUserForm()
  }
}

const handleExpenseCategoryResponse = response => {
  const data = response.detail[0]
  if (data.expense_categories) {
    refreshExpenseCategories(data.expense_categories)
    clearExpenseCategoryForm()
  }
}

const handleExpandUser = event => {
  button = $(event.target)
  hidden = button.parents('tr').find('p:last-child')
  hidden.slideToggle(300)
  if (button.html() == "Expand record") {
    button.html("Collapse record")
  } else {
    button.html("Expand record")
  }
}

const setupAdminDashboard = () => {
  const users = $('.admin-dashboard-users-wrapper')
  if (users) {
    $('#new-user-form').off('ajax:success', handleUserResponse)
    $('#new-user-form').on('ajax:success', handleUserResponse)
    users.off('click', '.expand', handleExpandUser)
    users.on('click', '.expand', handleExpandUser)
  }
}

const setupManageExpenseCategories = () => {
  const expenseCategories = $('.admin-dashboard-expense-categories-wrapper')
  if (expenseCategories) {
    $('#new-expense-category-form-wrapper form').off('ajax:success', handleExpenseCategoryResponse)
    $('#new-expense-category-form-wrapper form').on('ajax:success', handleExpenseCategoryResponse)
  }
}


document.addEventListener('turbolinks:load', () => {
  setupAdminDashboard()
  setupManageExpenseCategories()
});