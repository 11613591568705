const TransactionRowManager = require('./transactions/transaction_row_manager');

//0.22 is mileage value determined by IRS for medical travel
const mileageValue = 0.22;
const gasSelectDisplayName = "Gas reimbursements"

const transactionRowManager = new TransactionRowManager(() => getActiveRows($('#accounting_transactions .transaction_rows')));

const getActiveRows = (parentElement) => {
  return parentElement.find('.row[data-active-row]')
}

const countActiveRows = (parentElement) => {
  return getActiveRows(parentElement).length;
}

const markRowAsActive = (rowModObject) => {
  rowModObject.attr('data-active-row', '');
};

const markRowAsNotActive = (rowModObject) => {
  rowModObject.removeAttr('data-active-row');
};

const onAfterRemoveRow = (event, removedRow) => {
  markRowAsNotActive(removedRow)
  transactionRowManager.loadTransactionRows();
  updateTotal(event);
  updateCanDelete();
};

const onAfterInsertRow = (event, insertedRow) => {
  markRowAsActive(insertedRow);
  transactionRowManager.loadTransactionRows();
  updateCanDelete();
};

const updateCanDelete = () => {
  const count_rows = countActiveRows($('#accounting_transactions'));
  if (count_rows > 1) {
    getActiveRows($('#accounting_transactions .transaction_rows')).find(".delete_transaction_button").css("visibility", "visible");
  } else {
    getActiveRows($('#accounting_transactions .transaction_rows')).find(".delete_transaction_button").css("visibility", "hidden");
  }

}

const updateTotal = event => {
  if (event) {  roundDollarAmt(event), computeMileage(event), computeDollarAmt(event) }
  let total = 0;
  const transactions = $("#accounting_transactions input[name*='amount']:visible");
  const entries = transactions.map((i, t) => $(t).val());
  for (var i = 0; i < entries.length; i++) {
    const value = parseFloat(entries[i]);
    if (!isNaN(value)) {
      total += value;
    }
  }
  $('#total').text(`Total: \$${(Math.round(total * 100) / 100).toFixed(2)}`);
};

// computes dollar amount from mileage  
const computeDollarAmt = event => {
  if (!$(event.target).parents('.field').hasClass('distance-field')) {
    return false
  }
  const distance = $(event.target)
  const row = $(distance).parents('.row');
  const amount = $(row).find('.amount-field input');
  newAmount = $(distance).val() * mileageValue;
  amount.val(Math.ceil(newAmount).toFixed(2));
};

// rounding up the dollar amount before computing to mileage
const roundDollarAmt = event => {
  if (!$(event.target).parents('.field').hasClass('amount-field')) {
    return false
  }
  const amount_field = $(event.target)
  amount_field.val(Math.ceil(amount_field.val())); 
}

// computes mileage from dollar amount
const computeMileage = event => {
  if (!$(event.target).parents('.field').hasClass('amount-field')) {
    return false
  }
  const amount = $(event.target)
  const row = $(amount).parents('.row');
  const distance = $(row).find('.distance-field input');
  newDistance = $(amount).val() / mileageValue;
  distance.val((newDistance).toFixed(2));
}

const rowToGas = row => {
  const distance = $(row).find('.distance-field input');
  const amount = $(row).find('.amount-field input');
  $(row).addClass('mileage-row');
  $(row)
    .find('.amount-field label')
    .tooltip({ animation: false });
  distance.parent().show();
  distance.val(0);
  amount.val(0.0.toFixed(2));
  distance.focus();
};

const rowFromGas = row => {
  const distance = $(row).find('.distance-field input');
  const amount = $(row).find('.amount-field input');
  $(row).removeClass('mileage-row');
  $(row)
    .find('.amount-field label')
    .tooltip('disable');
  distance.parent().hide();
  amount.focus();
  if (distance.val() != 0) {
    distance.val(0);
    amount.val(0);
  }
};

const updateTransaction = select => {
  const optionText = select.options[select.selectedIndex].text;
  const row = $(select).parents('.row');

  const previousSelectedValue = (transactionRowManager.getByCategorySelect(select) && 
    transactionRowManager.getByCategorySelect(select).selectedCategory) || ""
  
  if (optionText == gasSelectDisplayName && previousSelectedValue != gasSelectDisplayName)  {
      // the user selected the gas reimbursements field and the previous selection was NOT gas reimbursements
    rowToGas(row);
  } 
  else if(optionText != gasSelectDisplayName && previousSelectedValue == gasSelectDisplayName) {
    // the user selected a non-gas reimbursements field and the previous selection was NOT gas reimbursements
    rowFromGas(row);
  }
};

const roundTransaction = event => {
  if (!$(event.target).parent().hasClass('amount-field')) {
    return false
  }
  const value = $(event.target).val()
  $(event.target).val(Math.ceil(value).toFixed(2))
  updateTotal()
}

const setupTotal = () => {
  let transactions = $('#accounting_transactions');
  if (transactions === null || transactions.length == 0) {
    return false;
  }
  transactions.on('keyup', updateTotal);
  transactions.on('change', updateTotal);
  transactions.on('paste', updateTotal);
  updateTotal();
};

const setupMileage = () => {
  let transactions = $('#accounting_transactions');
  if (transactions === null) {
    return false;
  }
  transactions.on('change', 'select', event => {

    updateTransaction(event.target)
    transactionRowManager.loadTransactionRows()
  });
};

const setupRounding = () => {
  let transactions = $('#accounting_transactions');
  transactions.on('blur', 'input', roundTransaction)
}

const setupCanDelete = () => {
  let transactions = $('#accounting_transactions');
  if (transactions === null || transactions.length == 0) {
    return false;
  }

  transactions.on('cocoon:after-insert', onAfterInsertRow);
  transactions.on('cocoon:after-remove', onAfterRemoveRow);

  updateCanDelete();
}

document.addEventListener('turbolinks:load', () => {
  transactionRowManager.loadTransactionRows();
  setupTotal();
  setupMileage();
  setupRounding();
  setupCanDelete();
  $('.amount-field label:visible').tooltip('dispose');
  $('.mileage-row .amount-field label:visible').tooltip({ animation: false });
});
